<template>
  <div>
    <div class="title">{{$t('titleI18n.BaseInfo')}}</div>
    <el-form :rules="rules" ref="formInfo" :model="formInfo" label-width="210px">
      <el-form-item :label="$t('formTitleI18n.BusinessType')" prop="businessType" required>
        <el-select
          v-model="formInfo.businessType"
          :placeholder="$t('formPlaceHolder.PhBusinessType')"
          style="width: 100%"
        >
          <el-option
            v-for="item in businessType"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.CoreUserName')" prop="payer" required>
        <el-select
          v-model="formInfo.payer"
          :placeholder="$t('formPlaceHolder.PhCoreUserName')"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in brandOwnerList"
            :key="index"
            :label="item.boUser.username"
            :value="item.boUser.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.OrderAmount')" prop="amount" required>
        <el-input
          v-model="formFormat.amount"
          :placeholder="$t('formPlaceHolder.PhOrderAmount')"
          @change="formatAmount"
          style="width: calc(100% - 130px)"
          maxlength="20"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.OrderTime')"
        prop="dueDate"
        required
        style="width: 100%;"
      >
        <el-date-picker
          style="width: 210px"
          v-model="formInfo.dueDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @focus="pickerDateFocus"
          :pickerOptions="pickerOptions"
          :placeholder="$t('formPlaceHolder.PhOrderTime')"
        ></el-date-picker>
        <span class="text-tips-gray">&nbsp;&nbsp;{{ $t("formPlaceHolder.AssetsDate60") }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ProductType')"
        prop="desctiption"
        required
        style="width: 100%;"
      >
        <el-input
          type="textarea"
          v-model="formInfo.desctiption"
          :placeholder="$t('formPlaceHolder.PhProductType')"
          :autosize="{ minRows: 4}"
          style="width: 90%;"
          maxlength="100"
        ></el-input>
      </el-form-item>

      <div class="title">{{$t('titleI18n.InvoiceInfo')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.InvoiceUpload')"
        prop="invoice"
        required
        style="width: 100%;"
      >
        <div class="form-upload-ph">{{ $t('formPlaceHolder.PhInvoiceUpload') }}</div>
        <el-upload
            class="upload-demo"
            action="/api/v1/upload/file"
            accept=".jpg, .jpeg, .png, .pdf, .JPG, .JPEG, .PNG, .PDF"
            :on-remove="handleRemove2"
            :before-remove="beforeRemove"
            :on-success="onSuccess2"
            multiple
            :headers="headers"
            :limit="10"
            :before-upload="beforeUpload"
            :file-list="invoice"
            :on-exceed="handleExceed"
        >
          <el-button size="small" class="btn-black">
            {{
              $t('fileUpLoad')
            }}
          </el-button>
        </el-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.PhInvoiceUploadTips') }}</div>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceNum')" prop="invoiceCode" required>
        <el-input
          v-model="formInfo.invoiceCode"
          :placeholder="$t('formPlaceHolder.PhInvoiceNum')"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceAmount')" prop="invoiceValue" required>
        <el-input
          v-model="formFormat.invoiceValue"
          :placeholder="$t('formPlaceHolder.PhInvoiceAmount')"
          @change="formatAmount2"
          style="width: calc(100% - 130px)"
          maxlength="20"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>

      <div v-if="contractNumber && contractName && contractAmount && contractValid">
        <div class="title">{{$t('titleI18n.ContractInfo')}}</div>
        <el-form-item :label="$t('formTitleI18n.ContractNum')" prop="contractNumber">
          <el-input
            v-model="formInfo.contractNumber"
            :placeholder="$t('formPlaceHolder.PhContractNum')"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('formTitleI18n.ContractName')" prop="contractName">
          <el-input
            v-model="formInfo.contractName"
            :placeholder="$t('formPlaceHolder.PhContractName')"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('formTitleI18n.ContractAmount')" prop="contractAmount">
          <el-input
            v-model="formFormat.contractAmount"
            :placeholder="$t('formPlaceHolder.PhContractAmount')"
            @change="formatAmount3"
            style="width: calc(100% - 130px)"
            maxlength="20"
          ></el-input>
          <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
        </el-form-item>
        <el-form-item :label="$t('formTitleI18n.ContractTime')" prop="contractValid">
          <el-date-picker
            style="width: 100%"
            v-model="formInfo.contractValid"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :placeholder="$t('formPlaceHolder.PhContractTime')"
          ></el-date-picker>
        </el-form-item>
      </div>

      <div class="title">{{$t('titleI18n.RepayType')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.RepayType')"
        prop="contractPaymentMode"
        required
        :label-width="language === 'zh-cn' ? '210px' : '300px'"
      >
        <el-select
          v-model="formInfo.contractPaymentMode"
          :placeholder="$t('formPlaceHolder.PhRepayType')"
          style="width: 100%"
        >
          <el-option
            v-for="item in repayType"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <div class="title">{{$t('titleI18n.TicketInfo')}}</div>
      <el-form-item
        :label="$t('formTitleI18n.TicketUpload')"
        prop="contractDetail"
        required
        style="width: 100%;"
      >
        <div class="form-upload-ph">{{ $t('formPlaceHolder.PhTicketUpload') }}</div>
        <el-upload
            class="upload-demo"
            action="/api/v1/upload/file"
            accept=".jpg, .jpeg, .png, .pdf, .JPG, .JPEG, .PNG, .PDF"
            :on-remove="handleRemove1"
            :before-remove="beforeRemove"
            :on-success="onSuccess1"
            multiple
            :headers="headers"
            :limit="10"
            :before-upload="beforeUpload"
            :file-list="contractDetail"
            :on-exceed="handleExceed"
        >
          <el-button size="small" class="btn-black">
            {{
              $t('fileUpLoad')
            }}
          </el-button>
        </el-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.PhTicketUploadTips') }}</div>
      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="submitClick()" :disabled="isSubmit">{{$t('submit')}}</el-button>
      <el-button class="btn-gray" @click="toList" :disabled="isSubmit">{{$t('btnI18n.Cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
// import FileUpload from "@/views/components/FileUpload";
// import FileUploadMultiple from "@/views/components/FileUploadMultiple";
import filter from "@/filters";
import { de } from 'date-fns/locale';

export default {
  name: "assets-create",
  components: {
    // FileUpload
    // FileUploadMultiple
  },
  data() {
    let reg2Decimal = (rule, value, callback) => {
      const reg = /^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/;
      // const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if (!value || value === "") {
        callback(new Error(this.$t('completeInput')));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('formValidate.AmountError')));
      }
    };
    let reg2DecimalNull = (rule, value, callback) => {
      const reg = /^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/;
      if (!value || value === "") {
        callback();
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('formValidate.AmountError')));
      }
    };
    return {
      headers: {},
      businessType: this.$enums.BUSINESS_TYPE,
      repayType: this.$enums.REPAY_TYPE,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 60 * 24 * 60 * 60 * 1000;//如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      requiredInvoiceUrl: false,
      requiredDetail: false,
      formFormat: {
        amount: "",
        invoiceValue: "",
        contractAmount: "",
      },
      formInfo: {
        businessType: "",
        contractAmount: "",
        contractName: "",
        contractNumber: "",
        contractValid: "",
        payer: "",
        invoiceValue: "",
        invoiceCode: "",
        invoice: "",
        amount: "",
        dueDate: "",
        desctiption: "",
        contractPaymentMode: "",
        contractDetail: ''
      },
      rules: {
        businessType: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        payer: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        amount: [{ required: true, trigger: 'blur' }],
        dueDate: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        desctiption: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invoice: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invoiceCode: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invoiceValue: [{ required: true, trigger: 'blur' }],
        contractNumber: [{ required: false, message: this.$t('completeInput'), trigger: 'blur' }],
        contractName: [{ required: false, message: this.$t('completeInput'), trigger: 'blur' }],
        contractAmount: [{ required: false, trigger: 'blur' }],
        contractValid: [{ required: false, message: this.$t('completeInput'), trigger: 'blur' }],
        contractPaymentMode: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        contractDetail: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
      },
      brandOwnerList: [],
      isSubmit: false,
      contractNumber: '',
      contractName: '',
      contractAmount: '',
      contractValid: '',
      invoice: [],
      contractDetail: [],
      mySelfArr: [
        {
          boUser: {
            id: "1538728455825477631",
            username: this.$store.getters['auth/user'].username
          }
        }
      ]
    };
  },
  created() {
    this.getBrandOwnerList();
    this.getDetail();
  },
  mounted() {
    this.headers = { token: this.$store.getters['auth/user'].token };
  },
  methods: {
    formatAmount() {
      this.formFormat.amount = this.formFormat.amount.replaceAll(",", "");
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.amount) && this.formFormat.amount > 0) {
        this.formFormat.amount = filter.numberFormat(this.formFormat.amount, 2);
        this.formInfo.amount = filter.reNumber(this.formFormat.amount);
      } else {
        this.formFormat.amount = 1;
        this.formInfo.amount = 1;
      }
    },
    formatAmount2() {
      this.formFormat.invoiceValue = this.formFormat.invoiceValue.replaceAll(",", "");
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.invoiceValue) && this.formFormat.invoiceValue > 0) {
        this.formFormat.invoiceValue = filter.numberFormat(this.formFormat.invoiceValue, 2);
        this.formInfo.invoiceValue = filter.reNumber(this.formFormat.invoiceValue);
      } else {
        this.formFormat.invoiceValue = 1;
        this.formInfo.invoiceValue = 1;
      }
    },
    formatAmount3() {
      this.formFormat.contractAmount = this.formFormat.contractAmount.replaceAll(",", "");
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.contractAmount) && this.formFormat.contractAmount > 0) {
        this.formFormat.contractAmount = filter.numberFormat(this.formFormat.contractAmount, 2);
        this.formInfo.contractAmount = filter.reNumber(this.formFormat.contractAmount);
      } else {
        this.formFormat.contractAmount = 1;
        this.formInfo.contractAmount = 1;
      }
    },
    getDetail() {
      let _this = this;
      if (!this.$route.query.id) return false;
      this.formInfo.id = this.$route.query.id;
      this.$axios.get("/v1/supplier/receivable-order", { params: { id: this.$route.query.id } }).then(result => {
        if (result) {
          _this.formInfo = result.data;
          let contractData = JSON.parse(JSON.stringify(result.data));
          _this.contractNumber = contractData.contractNumber;
          _this.contractName = contractData.contractName;
          _this.contractAmount = contractData.contractAmount;
          _this.contractValid = contractData.contractValid;
          _this.formInfo.businessType = filter.filterBusinessType(_this.formInfo.businessType);
          _this.formInfo.contractPaymentMode = filter.filterRepayType(_this.formInfo.contractPaymentMode);
          _this.formInfo.amount = Number(_this.formInfo.amount) / this.$enums.UNIT_MILLION;
          _this.formInfo.invoiceValue = Number(_this.formInfo.invoiceValue) / this.$enums.UNIT_MILLION;
          _this.formInfo.payer = _this.formInfo.payer ? _this.formInfo.payer : this.mySelfArr[0].boUser.username;
          if (_this.formInfo.contractAmount) {
            _this.formInfo.contractAmount = Number(_this.formInfo.contractAmount) / this.$enums.UNIT_MILLION;
          }

          _this.formFormat.amount = filter.numberFormat(this.formInfo.amount, 2);
          _this.formFormat.invoiceValue = filter.numberFormat(this.formInfo.invoiceValue, 2);
          _this.formFormat.contractAmount = filter.numberFormat(this.formInfo.contractAmount, 2);

          _this.fileToFileList1();
          _this.fileToFileList2();
          _this.formatAmount();
          _this.formatAmount2();
          _this.formatAmount3();
        }
      });
    },
    pickerDateFocus() {
      if (!this.formInfo.dueDate) {
        this.$set(this.formInfo, "dueDate", new Date(new Date().getTime() + 60 * 60 * 1000));
      }
    },
    beforeUpload(file) {
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.warning(this.$t("tipsMessage.UploadImgError"));
      }
      return isLt4M;
    },
    uploadSuccess(res, file) {
      this.formInfo.invoice = res.data;
    },
    getBrandOwnerList() {
      const data = {
        page: 1,
        pageSize: 10000
      };
      this.$axios.get("/v1/supplier/brandowners", { params: data }).then(result => {
        if (result.code === 0 || result.code === '0') {
          this.brandOwnerList = (result.data.rows).concat(this.mySelfArr);
        } else {
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => console.log(error));
    },
    submitClick() {
      this.isSubmit = true;
      let submit = true;
      if (!this.formInfo.invoice || !this.formInfo.contractDetail) {
        submit = false;
        this.requiredInvoiceUrl = !this.formInfo.invoice;
        this.requiredDetail = !this.formInfo.contractDetail;
        this.isSubmit = false;
        return this.$message.error(this.$t('plaseUpload'));
      }
      if ((this.formInfo.contractAmount || this.formInfo.contractName || this.formInfo.contractNumber || this.formInfo.contractValid)
        && (!(this.formInfo.contractAmount && this.formInfo.contractName && this.formInfo.contractNumber && this.formInfo.contractValid))) {
        submit = false;
        this.isSubmit = false;
        return this.$message.error(this.$t('completeInput'));
      }
      if (!this.formInfo.contractAmount) {
        delete this.formInfo.contractAmount;
      }
      if (!this.formInfo.contractName) {
        delete this.formInfo.contractName;
      }
      if (!this.formInfo.contractNumber) {
        delete this.formInfo.contractNumber;
      }
      if (!this.formInfo.contractValid) {
        delete this.formInfo.contractValid;
      }
      if (this.formInfo.payer === this.mySelfArr[0].boUser.id || this.formInfo.payer === this.mySelfArr[0].boUser.username) {
        delete this.formInfo.payer;
      }
      submit && this.$refs.formInfo.validate((valid) => {
        if (!valid) {
          submit = false;
          this.isSubmit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      if (submit) {
        let info = this.formInfo;
        if (this.formInfo.businessType === this.$t('statusI18n.AssetsAccountsReceivables')) {
          info.businessType = '1';
        }
        switch (this.formInfo.contractPaymentMode) {
          case this.$t('statusI18n.AssetsBank'):
            info.contractPaymentMode = 1;
            break;
          case this.$t('statusI18n.AssetsCommercialBill'):
            info.contractPaymentMode = 2;
            break;
          case this.$t('statusI18n.AssetsOthers'):
            info.contractPaymentMode = 3;
            break;
        }
        info.amount = ((info.amount) * this.$enums.UNIT_MILLION).toString();;
        info.invoiceValue = ((info.invoiceValue) * this.$enums.UNIT_MILLION).toString();
        if (info.contractAmount) {
          info.contractAmount = ((info.contractAmount) * this.$enums.UNIT_MILLION).toString();
        }
        delete info.status;
        delete info.createdAt;
        delete info.payerName;
        this.$axios.post("/v1/supplier/receivable-order", info).then(result => {
          if (result.code === 0 || result.code === '0') {
            this.isSubmit = false;
            this.$router.push({ path: "/assets-list" });
          } else {
            this.isSubmit = false;
            this.$message({ type: 'error', message: result.message });
          }
        }).catch((error) => {
          console.log("submit form error", error);
          this.isSubmit = false;
        });
      }
    },
    toList() {
      this.$router.push({ path: "/assets-list" });
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$t('sureRemove')} ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message({
        message: this.$t('companyCreate.limitedSelected10'),
        type: 'warning',
      });
    },
    handleRemove1(file, fileList) {
      let dataArr = [];
      if (!fileList[0].response) {
        this.contractDetail.map((item) => {
          if (item.url !== file.url) {
            dataArr.push(item.url);
          }
        });
      } else {
        fileList.map((file) => {
          if (file.response) {
            dataArr.push(file.response.data);
          }
        });
      }
      this.formInfo.contractDetail = JSON.stringify(dataArr);
      this.fileToFileList1();
    },
    onSuccess1(response, file, fileList) {
      if (response.data) {
        let dataArr = [];
        if (!fileList[0].response) {
          dataArr.push(response.data);
          this.contractDetail.map((item) => {
            if (item.url !== response.data) {
              dataArr.push(item.url);
            }
          });
        } else {
          fileList.map((file) => {
            if (file.response) {
              dataArr.push(file.response.data);
            }
          });
        }
        this.formInfo.contractDetail = JSON.stringify(dataArr);
        this.fileToFileList1();
      }
    },
    fileToFileList1() {
      this.contractDetail = [];
      JSON.parse(this.formInfo.contractDetail).map((item) => {
        this.contractDetail.push({
          name: item.split("/")[item.split("/").length - 1],
          url: item,
        });
      });
    },
    handleRemove2(file, fileList) {
      let dataArr = [];
      if (!fileList[0].response) {
        this.invoice.map((item) => {
          if (item.url !== file.url) {
            dataArr.push(item.url);
          }
        });
      } else {
        fileList.map((file) => {
          if (file.response) {
            dataArr.push(file.response.data);
          }
        });
      }
      this.formInfo.invoice = JSON.stringify(dataArr);
      this.fileToFileList2();
    },
    onSuccess2(response, file, fileList) {
      if (response.data) {
        let dataArr = [];
        if (!fileList[0].response) {
          dataArr.push(response.data);
          this.invoice.map((item) => {
            if (item.url !== response.data) {
              dataArr.push(item.url);
            }
          });
        } else {
          fileList.map((file) => {
            if (file.response) {
              dataArr.push(file.response.data);
            }
          });
        }
        this.formInfo.invoice = JSON.stringify(dataArr);
        this.fileToFileList2();
      }
    },
    fileToFileList2() {
      this.invoice = [];
      JSON.parse(this.formInfo.invoice).map((item) => {
        this.invoice.push({
          name: item.split("/")[item.split("/").length - 1],
          url: item,
        });
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style scoped lang="scss">
.el-form-item {
  width: 550px;
  display: inline-block;
}
.check-example {
  margin-left: 15px;
}
::v-deep .avatar-uploader .el-upload {
  width: 148px;
  height: 148px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .upload-img {
    width: 100%;
    height: 100%;
  }
}
</style>
